import React from "react";
import { FacebookShareButton, FacebookShareCount, TwitterShareButton, FacebookIcon, TwitterIcon, } from "react-share";
import { rhythm } from "@/utils/typography";
import HatenaShareButton from "./hatena-share-button";
const SNS = (props) => {
    const { post, location } = props;
    const url = location.href;
    const iconSize = 32;
    const title = post.frontmatter.title;
    const quote = post.excerpt;
    return (<div className="social-links" style={{
        marginBottom: rhythm(1.5)
    }}>
      <ul style={{
        display: `table-row`
    }}>
        <li style={{
        display: `table-cell`,
        padding: `5px`
    }}>
          <TwitterShareButton url={url} title={title} style={{
        cursor: `pointer`
    }}>
            <TwitterIcon round size={iconSize}/>
          </TwitterShareButton>
        </li>
        <li style={{
        display: `table-cell`,
        padding: `5px`,
        textAlign: `center`
    }}>
          <FacebookShareButton url={url} quote={quote} style={{
        cursor: `pointer`
    }}>
            <FacebookIcon round size={iconSize}/>
          </FacebookShareButton>
          <FacebookShareCount url={url}/>
        </li>
        <li style={{
        display: `table-cell`,
        padding: `5px`,
        textAlign: `center`
    }}>
          <HatenaShareButton />
        </li>
      </ul>
    </div>);
};
export default SNS;

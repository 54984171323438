import React, { useEffect, useRef } from "react";
let scriptIsLoaded = false;
const HatenaShareButton = () => {
    const rootDOMRef = useRef(null);
    useEffect(() => {
        if (scriptIsLoaded) {
            return;
        }
        scriptIsLoaded = true;
        const scriptDOM = document.createElement('script');
        scriptDOM.type = 'text/javascript';
        scriptDOM.src = 'https://b.st-hatena.com/js/bookmark_button.js';
        scriptDOM.charset = 'utf-8';
        const rootDOM = rootDOMRef.current;
        if (rootDOM) {
            rootDOM.appendChild(scriptDOM);
        }
        const styleDOM = document.createElement('style');
        styleDOM.textContent = '.hatena-share-button iframe { margin-bottom: 0; }';
        document.head.appendChild(styleDOM);
    }, []);
    return (<div className="hatena-share-button" ref={rootDOMRef}>
      <a href="https://b.hatena.ne.jp/entry/" className="hatena-bookmark-button" data-hatena-bookmark-layout="vertical-normal" data-hatena-bookmark-lang="ja" title="このエントリーをはてなブックマークに追加">
        <img src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png" alt="このエントリーをはてなブックマークに追加" width="20" height="20" style={{
        border: `none`
    }}/>
      </a>
    </div>);
};
export default HatenaShareButton;

import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "@/components/layout";
import SEO from "@/components/seo";
import { rhythm, scale } from "@/utils/typography";
import SNS from "@/components/sns";
class BlogPostTemplate extends React.Component {
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        const post = this.props.data.markdownRemark;
        const siteTitle = ((_c = (_b = (_a = this.props.data) === null || _a === void 0 ? void 0 : _a.site) === null || _b === void 0 ? void 0 : _b.siteMetadata) === null || _c === void 0 ? void 0 : _c.title) || '';
        const { previous, next } = this.props.pageContext;
        return (<Layout location={this.props.location} title={siteTitle}>
        <SEO title={((_e = (_d = post) === null || _d === void 0 ? void 0 : _d.frontmatter) === null || _e === void 0 ? void 0 : _e.title) || ''} description={((_g = (_f = post) === null || _f === void 0 ? void 0 : _f.frontmatter) === null || _g === void 0 ? void 0 : _g.description) || ((_h = post) === null || _h === void 0 ? void 0 : _h.excerpt) || ''}/>
        <article>
          <header>
            <h1 style={{
            marginTop: rhythm(1),
            marginBottom: 0,
        }}>
              {(_k = (_j = post) === null || _j === void 0 ? void 0 : _j.frontmatter) === null || _k === void 0 ? void 0 : _k.title}
            </h1>
            <p style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
        }}>
              {(_m = (_l = post) === null || _l === void 0 ? void 0 : _l.frontmatter) === null || _m === void 0 ? void 0 : _m.date}
            </p>
            <SNS post={post} location={this.props.location}/>
          </header>
          <section dangerouslySetInnerHTML={{ __html: ((_o = post) === null || _o === void 0 ? void 0 : _o.html) || '' }}/>
          <hr style={{
            marginBottom: rhythm(1),
        }}/>
          <footer>
            
            <SNS post={post} location={this.props.location}/>
          </footer>
        </article>

        <nav>
          <ul style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
        }}>
            <li>
              {previous && (<Link to={((_q = (_p = previous) === null || _p === void 0 ? void 0 : _p.fields) === null || _q === void 0 ? void 0 : _q.slug) || ''} rel="prev">
                  ← {(_s = (_r = previous) === null || _r === void 0 ? void 0 : _r.frontmatter) === null || _s === void 0 ? void 0 : _s.title}
                </Link>)}
            </li>
            <li>
              {next && (<Link to={((_u = (_t = next) === null || _t === void 0 ? void 0 : _t.fields) === null || _u === void 0 ? void 0 : _u.slug) || ''} rel="next">
                  {((_w = (_v = next) === null || _v === void 0 ? void 0 : _v.frontmatter) === null || _w === void 0 ? void 0 : _w.title) || ''} →
                </Link>)}
            </li>
          </ul>
        </nav>
      </Layout>);
    }
}
export default BlogPostTemplate;
export const pageQuery = graphql `
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
